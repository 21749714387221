/**
 * React and friends
 */
import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';

import { trackEvent } from '../analytics';

/**
 * Custom components for the screens
 */
import ScenarioScreen from '../components/MortgageAdvisor/Scenario';
import HomeScreen from '../components/Tool/Home';
import ExistingOfferScreen from '../components/MortgageAdvisor/ExistingOffer';
import AdditionalInfoScreen from '../components/Tool/AdditionalInfo';
import OfferFoundScreen from '../components/Tool/OfferFound';
import LoadingOffersScreen from '../components/Tool/LoadingOffers';
import ResultOptionsScreen from '../components/Tool/ResultOptions';
import { API_VERSION, apiInternalUrl } from '../constants';

const GeneralContext = createContext();

const { Provider, Consumer } = GeneralContext;

export const SnackbarConsumer = Consumer;

/**
 * Hook provider for the mortgage advisor tool
 */
export const MortgageAdvisorProvider = ({ children }) => {
  /**
   * State for the data
   */
  const [data, setValue] = useState({
    hasOffer: true,
    loanAmount: 250000,
    loanProgram: 'Fixed30Year',
    ltv: 0.7,
    purchasePrice: 300000,
    rate: 6,
    state: '',
    creditScore: '700',
    totalFees: 1500,
    purpose: 'Purchase',
    numberOfDays: 4,
    N: 2000,
    pointFees: 0,
    yearsBeforeMoving: 10,
    goal: 'Minimize monthly payment (for sure)',
    tool: 'COMPARE_OFFERS',
    financeAnswers: [],
  });

  /**
   * State to keep the best rates for user
   */
  const [bestRates, setBestRates] = useState([]);

  /**
   * state for the step
   */
  const [StepComponent, setStepComponent] = useState(steps['home']);

  const setStep = (nextStep) => {
    setStepComponent(steps[nextStep]);
  };

  const setData = useCallback(
    (value) => {
      setValue({ ...data, ...value });
    },
    [data]
  );

  const getBestRates = useCallback(() => {
    (async () => {
      try {
        const body = {
          ...data,
          ltv: data.loanAmount / data.purchasePrice,
        };

        const response = await fetch(
          `${apiInternalUrl}/${API_VERSION.V1}/mortgage/bestrates`,
          {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
          }
        );

        if (response.status !== 200)
          throw new Error('Error getting best rates');

        const responseData = await response.json();
        trackEvent('Used Finance Tool', { type: 'MORTGAGE_ADVISOR' });

        setBestRates(responseData || []);

        if (!responseData.length) {
          setStep('offerFound');

          return null;
        }

        setStep('resultOptions');
      } catch (err) {
        setStep('offerFound');

        return null;
      }
    })();
  }, [data]);

  /**
   * Memoized value for callbacks
   */
  const value = useMemo(
    () => ({
      setData,
      data,
      StepComponent,
      setStep,
      getBestRates,
      bestRates,
    }),
    [setData, setStep, data, StepComponent, getBestRates, bestRates]
  );

  return <Provider value={value}>{children}</Provider>;
};

/**
 * Context export to use hook
 */
export default () => useContext(GeneralContext) || {};

/** Map for screens */
const steps = {
  home: HomeScreen,
  existingOffer: ExistingOfferScreen,
  additionalInfo: AdditionalInfoScreen,
  offerFound: OfferFoundScreen,
  loadingOffers: LoadingOffersScreen,
  resultOptions: ResultOptionsScreen,
};
