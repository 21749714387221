import { API_VERSION, apiInternalUrl } from '../constants';

const HAM_ENDPOINT = `${apiInternalUrl}/v1/mortgage/home-affordability`;

interface IHomeAffordabilityRequest {
  zipCode: string;
  totalSavings: number;
  annualIncome: number;
  creditScore: number;
  propertyType: string;
  alimony?: number;
  otherMonthlyDebts?: number;
}

interface IHomeAffordabilityResponse {
  maximumHomeValue: number;
}

export const getHomeAffordability = async (
  props: IHomeAffordabilityRequest
) => {
  const queryString = new URLSearchParams(props as any).toString();

  const response = await fetch(`${HAM_ENDPOINT}?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 200) {
    return (await response.json()) as IHomeAffordabilityResponse;
  } else {
    throw new Error('Something went wrong');
  }
};

export enum PROPERTY_TYPE {
  SINGLE_FAMILY = 'SINGLE_FAMILY',
  MULTI_UNIT = 'MULTI_UNIT',
  CONDO = 'CONDO',
  TOWNHOUSE = 'TOWNHOUSE',
}

export const PROPERTY_TYPE_LABELS = {
  [PROPERTY_TYPE.SINGLE_FAMILY]: 'Single Family',
  [PROPERTY_TYPE.MULTI_UNIT]: 'Multi-Unit',
  [PROPERTY_TYPE.CONDO]: 'Condo',
  [PROPERTY_TYPE.TOWNHOUSE]: 'Townhouse',
};

export enum CREDIT_RATING_CATEGORY {
  EXCELLENT = 'EXCELLENT',
  GOOD = 'GOOD',
  FAIR = 'FAIR',
  POOR = 'POOR',
  NO = 'NO/LOW',
}

export const CREDIT_RATING_LABELS = {
  [CREDIT_RATING_CATEGORY.NO]: 'No/Low Score',
  [CREDIT_RATING_CATEGORY.POOR]: 'Poor (580 - 659)',
  [CREDIT_RATING_CATEGORY.FAIR]: 'Fair (660 - 699)',
  [CREDIT_RATING_CATEGORY.GOOD]: 'Good (700 - 739)',
  [CREDIT_RATING_CATEGORY.EXCELLENT]: 'Excellent (740+)',
};

export const LABEL_TO_CREDIT_RATING = {
  'No/Low Score': CREDIT_RATING_CATEGORY.NO,
  'Poor (580 - 659)': CREDIT_RATING_CATEGORY.POOR,
  'Fair (660 - 699)': CREDIT_RATING_CATEGORY.FAIR,
  'Good (700 - 739)': CREDIT_RATING_CATEGORY.GOOD,
  'Excellent (740+)': CREDIT_RATING_CATEGORY.EXCELLENT,
};

export const mapCreditRatingToScore = (creditRating: string) => {
  var rating = LABEL_TO_CREDIT_RATING[creditRating] || creditRating;

  switch (rating) {
    case CREDIT_RATING_CATEGORY.EXCELLENT:
      return 740;
    case CREDIT_RATING_CATEGORY.GOOD:
      return 700;
    case CREDIT_RATING_CATEGORY.FAIR:
      return 660;
    case CREDIT_RATING_CATEGORY.POOR:
      return 580;
    default:
      return 0;
  }
};
